// src/components/Flights.tsx
import React, { useState } from "react";
import flightService from "../services/flightService";
import SearchBar from "../components/SearchBar";
import './Flights.css'; // Assuming you create a CSS file for styling

// Define the interface for a flight



interface Flight {
    flightId: string;
    airline: string;
    departure: string; // ISO 8601 format
    arrival: string; // ISO 8601 format
    from: string; // Departure airport code
    to: string; // Arrival airport code
    price: number;
    availableSeats: number;
  }
  
  const Flights = () => {
    const [flights, setFlights] = useState<Flight[]>([]);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
  
    const handleSearch = async (searchParams: {
      flightId: string;
      airline: string;
      from: string;
      to: string;
      departureDate: string;
    }) => {
      // If both 'from' and 'to' are empty, clear flights and set error message
      if (!searchParams.from && !searchParams.to) {
        setFlights([]);
        setErrorMessage("Please enter 'From' and 'To' airport codes to search.");
        return;
      }
  
      const data = await flightService.getFlights();
      // Filter flights based on the search parameters
      const filteredFlights = data.filter((flight) => {
        const matchesFrom = flight.from.toLowerCase().includes(searchParams.from.toLowerCase());
        const matchesTo = flight.to.toLowerCase().includes(searchParams.to.toLowerCase());
        return matchesFrom && matchesTo;
      });
  
      if (filteredFlights.length === 0) {
        setErrorMessage("No flights found for the given search criteria.");
      } else {
        setErrorMessage(null); // Clear error message if flights found
      }
      setFlights(filteredFlights);
    };
  
    return (
      <div>
        <h2>Search Flights</h2>
        <SearchBar onSearch={handleSearch} />
        {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
        <ul className="flights-list">
          {flights.map((flight) => (
            <li key={flight.flightId}>
              <strong>{flight.airline}</strong> | Flight ID: {flight.flightId} | 
              From: {flight.from} To: {flight.to} | 
              Departure: {new Date(flight.departure).toLocaleString()} | 
              Arrival: {new Date(flight.arrival).toLocaleString()} | 
              Price: ${flight.price.toFixed(2)} | 
              Available Seats: {flight.availableSeats}
            </li>
          ))}
        </ul>
      </div>
    );
  };
  
  export default Flights;
