// src/services/userService.ts
const userService = {
    getUsers: async () => {
        // Sample user data, replace this with your API call logic
        return [
            { userId: "USR001", name: "John Doe", email: "john.doe@example.com" },
            { userId: "USR002", name: "Jane Smith", email: "jane.smith@example.com" },
            { userId: "USR003", name: "Alice Johnson", email: "alice.johnson@example.com" }
        ];
    }
};

export default userService;
