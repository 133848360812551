// src/services/bookingService.ts
const bookingService = {
    getBookings: async () => {
        // Sample booking data, replace this with your API call logic
        return [
            { bookingId: "BK001", userId: "USR001", status: "confirmed" },
            { bookingId: "BK002", userId: "USR002", status: "pending" },
            { bookingId: "BK003", userId: "USR003", status: "confirmed" }
        ];
    }
};

export default bookingService;
