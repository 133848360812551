// src/pages/Users.tsx
import React, { useEffect, useState } from 'react';
import userService from '../services/userService';

// Define the interface for a user with an optional phone property
interface User {
    userId: string;
    name: string;
    email: string;
    phone?: string; // Make phone optional
}

const Users: React.FC = () => {
    const [users, setUsers] = useState<User[]>([]);

    useEffect(() => {
        const fetchUsers = async () => {
            const data = await userService.getUsers();
            setUsers(data);
        };
        fetchUsers();
    }, []);

    return (
        <div>
            <h2>Registered Users</h2>
            <ul>
                {users.map(user => (
                    <li key={user.userId}>
                        {user.name} - {user.email} {user.phone && `- ${user.phone}`} 
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default Users;
