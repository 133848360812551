import React from 'react';
import { useLocation } from 'react-router-dom';

const SearchResults: React.FC = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search).get('q');

  return (
    <div>
      <h2>Search Results</h2>
      {query ? <p>Results for: <strong>{query}</strong></p> : <p>No results found.</p>}
      {/* Here you can render your search results */}
    </div>
  );
};

export default SearchResults;
