const flightService = {
    getFlights: async () => {
      return [
        {
          flightId: "FL123",
          airline: "Airline A",
          departure: "2024-10-15T08:00:00Z",
          arrival: "2024-10-15T10:00:00Z",
          from: "JFK",
          to: "LAX",
          price: 299.99,
          availableSeats: 20,
        },
        {
          flightId: "FL456",
          airline: "Airline B",
          departure: "2024-10-16T09:30:00Z",
          arrival: "2024-10-16T12:00:00Z",
          from: "LAX",
          to: "ORD",
          price: 199.99,
          availableSeats: 15,
        },
        {
          flightId: "FL789",
          airline: "Airline C",
          departure: "2024-10-17T15:00:00Z",
          arrival: "2024-10-17T18:00:00Z",
          from: "ORD",
          to: "JFK",
          price: 249.99,
          availableSeats: 25,
        },
      ];
    },
  };
  
  export default flightService;
  