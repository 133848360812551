// src/routes.tsx
import React from 'react';
import { Route, Routes as RouterRoutes } from 'react-router-dom';
import Flights from './pages/Flights';
import Hotels from './pages/Hotels';
import Bookings from './pages/Bookings';
import Users from './pages/Users';
import SearchResults from './pages/SearchResult';
import HomePage from './pages/HomePage';

const Routes: React.FC = () => {
    return (
        <RouterRoutes>
            <Route path="/" element={<HomePage />} />
            <Route path="/search" element={<SearchResults />} />
            <Route path="/flights" element={<Flights />} />
            <Route path="/hotels" element={<Hotels />} />
            <Route path="/bookings" element={<Bookings />} />
            <Route path="/users" element={<Users />} />
        </RouterRoutes>
    );
};

export default Routes;
