// src/pages/Hotels.tsx
import React, { useEffect, useState } from 'react';
import hotelService from '../services/hotelService';

// Define the interface for a hotel
interface Hotel {
    hotelId: string;
    name: string;
    pricePerNight: number;
}

const Hotels: React.FC = () => {
    // Use the Hotel interface to define the state type
    const [hotels, setHotels] = useState<Hotel[]>([]);

    useEffect(() => {
        const fetchHotels = async () => {
            const data = await hotelService.getHotels();
            setHotels(data);
        };
        fetchHotels();
    }, []);

    return (
        <div>
            <h2>Available Hotels</h2>
            <ul>
                {hotels.map(hotel => (
                    <li key={hotel.hotelId}>
                        {hotel.name} - ${hotel.pricePerNight} per night
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default Hotels;
