// src/pages/Bookings.tsx
import React, { useEffect, useState } from 'react';
import bookingService from '../services/bookingService';

// Define the interface for a booking
interface Booking {
    bookingId: string;
    userId: string;
    flightId?: string; // Optional
    hotelId?: string; // Optional
    totalPrice?: number; // Optional
    bookingDate?: string; // Optional
    status: string;
}

const Bookings: React.FC = () => {
    // Use the Booking interface to define the state type
    const [bookings, setBookings] = useState<Booking[]>([]);

    useEffect(() => {
        const fetchBookings = async () => {
            try {
                const data = await bookingService.getBookings();
                setBookings(data);
            } catch (error) {
                console.error("Failed to fetch bookings:", error);
            }
        };
        fetchBookings();
    }, []);

    return (
        <div>
            <h2>Your Bookings</h2>
            <ul>
                {bookings.map(booking => (
                    <li key={booking.bookingId}>
                        Booking ID: {booking.bookingId} - Status: {booking.status} - 
                        Total Price: ${booking.totalPrice ? booking.totalPrice.toFixed(2) : 'N/A'} - 
                        Booking Date: {booking.bookingDate ? booking.bookingDate : 'N/A'}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default Bookings;
