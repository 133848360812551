// src/services/hotelService.ts
const hotelService = {
    getHotels: async () => {
        // Sample hotel data, replace this with your API call logic
        return [
            { hotelId: "HT101", name: "Hotel A", pricePerNight: 120.00 },
            { hotelId: "HT202", name: "Hotel B", pricePerNight: 150.00 },
            { hotelId: "HT303", name: "Hotel C", pricePerNight: 200.00 }
        ];
    }
};

export default hotelService;
